//libraries
import * as React from "react";
//containers
import Layout from "../containers/layout";
//components
import Seo from "../components/seo";
import STACoaches from "../components/coaches/sta-coaches";
import Coaches from "../components/coaches/coaches";
import PlayCoaches from "../components/coaches/play-coaches";

const AllCoaches: React.FC = () => (
  <Layout>
    <Seo
      title="Coaches"
      description="Seychelles tennis association coaches and seychellois coaches living abroad"
    />
    <STACoaches />
    <Coaches />
    <PlayCoaches />
  </Layout>
);

export default AllCoaches;
