import * as React from "react";
import findImage from "../../utils/findImage";
import Avatar from "../avatar";

interface PlayCoachProfileProps {
  name: string;
  photo: string;
  club: string;
  coachLocation: string;
  images: Array<any>;
}

const PlayCoachProfile: React.FC<PlayCoachProfileProps> = ({
  name,
  photo,
  club,
  coachLocation,
  images,
}) => {
  const image = findImage(photo, images);
  return (
    <div className="flex items-center space-x-4 lg:space-x-6">
      <Avatar name={name} image={image} />
      <div className="text-lg leading-6 space-y-1">
        <h3 className="text-blue-500 text-sm sm:text-base md:text-lg">
          {name}
        </h3>
        <p className="text-xs sm:text-sm text-green">{club}</p>
        <p className="text-light text-xs sm:text-sm">{coachLocation}</p>
      </div>
    </div>
  );
};

export default PlayCoachProfile;
