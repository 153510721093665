import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
//containers
import Container from "../../containers/container";
//components
import PlayCoachProfile from "./play-coach-profile";
//hooks
import useImages from "../../hooks/useImages";

interface CoachProps {
  name: string;
  photo: string;
  coachLocation: string;
  club: string;
}

const PlayCoaches: React.FC = () => {
  const images = useImages();
  const { coaches } = useStaticQuery(
    graphql`
      query {
        coaches: allPeopleCsv(
          filter: { coachLevel: { eq: "PLAY AND STAY LEVEL" } }
        ) {
          nodes {
            name
            photo
            club
            coachLocation
          }
        }
      }
    `
  );

  return (
    <>
      <div className="bg-white">
        <Container>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
            <div className="space-y-5 sm:space-y-4">
              <h4 className="text-xl sm:text-2xl text-blue-500 font-semibold tracking-tight">
                PLAY AND STAY LEVEL
              </h4>
              <p className="text-xl text-gray-500">
                To promote tennis as easy, fun and healthy
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul
                role="list"
                className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8"
              >
                {coaches.nodes
                  .sort((a: CoachProps, b: CoachProps) =>
                    a.name.split(" ")[b.name.split(" ").length - 1] >
                    b.name.split(" ")[a.name.split(" ").length - 1]
                      ? 1
                      : -1
                  )
                  .map((coach: CoachProps) => (
                    <PlayCoachProfile
                      key={coach.name}
                      images={images}
                      {...coach}
                    />
                  ))}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PlayCoaches;
