import * as React from "react";
//components
import Avatar from "../avatar";
//utils
import findImage from "../../utils/findImage";

interface STACoachProfileProps {
  name: string;
  photo;
  coachTitle: string;
  coachLevel: string;
  images: Array<any>;
}

const STACoachProfile: React.FC<STACoachProfileProps> = ({
  name,
  photo,
  coachTitle,
  coachLevel,
  images,
}) => {
  const image = findImage(photo, images);
  return (
    <div className="mx-auto max-w-sm bg-white sm:bg-transparent p-4">
      <div className="md:pl-2 flex items-center space-x-4 lg:space-x-6 py-4 max-w-sm">
        <Avatar name={photo} image={image} />
        <div className="text-lg leading-6 space-y-1">
          <h3 className="text-sm sm:text-base md:text-lg">{name}</h3>
          <p className="text-green text-xs sm:text-sm">{coachTitle}</p>
          <p className="text-light text-xs sm:text-sm">{coachLevel}</p>
        </div>
      </div>
    </div>
  );
};

export default STACoachProfile;
