//libraries
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
//containers
import Container from "../../containers/container";
//components
import STACoachProfile from "./sta-coach-profile";
//hooks
import useImages from "../../hooks/useImages";

interface CoachProps {
  name: string;
  photo: string;
  coachTitle: string;
  coachLevel: string;
}

const STACoaches: React.FC = () => {
  const images = useImages();
  const { coaches } = useStaticQuery(
    graphql`
      query {
        coaches: allPeopleCsv(filter: { coachLocation: { eq: "STA" } }) {
          nodes {
            name
            photo
            coachTitle
            coachLevel
          }
        }
      }
    `
  );

  return (
    <div className="bg-light">
      <Container>
        <div className="py-12 sm:py-24">
          <h3 className="text-center font-semibold text-3xl sm:text-4xl text-blue-500 mb-8 sm:mb-12">
            SEYCHELLES TENNIS ASSOCIATION'S COACHES
          </h3>
          <div className="w-6 h-1 mb-4 bg-green mx-auto" />
          <p className="max-w-2xl mx-auto uppercase text-center text-xs font-light leading-6 sm:text-sm md:text-base text-blue-400 mb-10 sm:mb-12">
            Tennis Club is honored to work with the country’s league best tennis
            players that now can become your personal coach and help you win
          </p>
          <div className="mx-auto">
            <div
              role="list"
              className="sm:grid sm:grid-cols-2 lg:grid-cols-3 space-y-4 gap-4 sm:space-y-0 lg:max-w-7xl mx-auto"
            >
              {coaches.nodes.map((coach: CoachProps) => (
                <STACoachProfile key={coach.name} {...coach} images={images} />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default STACoaches;
