import * as React from "react";
import findImage from "../../utils/findImage";
//components
import Avatar from "../avatar";

interface CoachProfileProps {
  name: string;
  photo;
  club: string;
  coachLevel?: string;
  coachLocation: string;
  images: Array<any>;
}

const CoachProfile: React.FC<CoachProfileProps> = ({
  name,
  photo,
  club,
  coachLevel,
  coachLocation,
  images,
}) => {
  const image = findImage(photo, images);
  return (
    <div className="mx-auto sm:mx-0 max-w-sm bg-light sm:bg-transparent p-4">
      <div className="text-center">
        <Avatar name={photo} image={image} />
        <div className="font-medium text-lg leading-6 space-y-1">
          <h3 className="text-blue-500 text-sm sm:text-base md:text-lg">
            {name}
          </h3>
          <p className="text-green text-xs sm:text-sm">{coachLevel}</p>
          <p className="text-xs sm:text-sm text-blue-500">{club}</p>
          <p className="text-light text-xs sm:text-sm">{coachLocation}</p>
        </div>
      </div>
    </div>
  );
};

export default CoachProfile;
