//libraries
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
//containers
import Container from "../../containers/container";
//components
import CoachProfile from "./coach-profile";
//hooks
import useImages from "../../hooks/useImages";

interface CoachProps {
  name: string;
  photo: string;
  club: string;
  coachLocation: string;
  coachLevel: string;
}

const Coaches: React.FC = () => {
  const images = useImages();
  const { coaches } = useStaticQuery(
    graphql`
      query {
        coaches: allPeopleCsv(
          filter: {
            coachLocation: { ne: "STA" }
            coachLevel: { nin: ["", "PLAY AND STAY LEVEL"] }
          }
        ) {
          nodes {
            name
            photo
            club
            coachLocation
            coachLevel
          }
        }
      }
    `
  );

  return (
    <div className="bg-white">
      <Container>
        <div className="py-12 lg:py-24">
          <h3 className="text-center font-semibold text-3xl lg:text-4xl text-blue-500 mb-8 sm:mb-12">
            COACHES IN SEYCHELLES AND ABROAD
          </h3>
          <div className="w-6 h-1 mb-4 bg-green mx-auto" />
          <p className="lg:max-w-2xl mx-auto uppercase text-center text-xs font-light leading-6 sm:text-sm md:text-base text-blue-400 mb-10 sm:mb-16 lg:mb-24">
            COACHES IN SEYCHELLES OR REPRESENTING SEYCHELLES ABROAD
          </p>
          <div
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-12 sm:space-y-0 lg:gap-x-8"
          >
            {coaches.nodes
              .sort((a: CoachProps, b: CoachProps) =>
                a.name.split(" ")[b.name.split(" ").length - 1] >
                b.name.split(" ")[a.name.split(" ").length - 1]
                  ? 1
                  : -1
              )
              .map((coach: CoachProps) => (
                <CoachProfile key={coach.name} images={images} {...coach} />
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Coaches;
